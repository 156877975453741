export default {
    0: "Dirt Block",
    1: "Stone Block",
    2: "Grass Block",
    6: "Iron Ore",
    7: "Copper Ore",
    8: "Gold Ore",
    9: "Silver Ore",
    22: "Demonite Ore",
    23: "Corrupt Grass Block",
    25: "Ebonstone Block",
    30: "Wood",
    32: "Corruption Thorns",
    37: "Meteorite",
    38: "Gray Brick",
    39: "Red Brick",
    40: "Clay Block",
    41: "Blue Brick",
    43: "Green Brick",
    44: "Pink Brick",
    45: "Gold Brick",
    46: "Silver Brick",
    47: "Copper Brick",
    48: "Spike",
    51: "Cobweb",
    52: "Vines",
    53: "Sand Block",
    54: "Glass",
    56: "Obsidian",
    57: "Ash Block",
    58: "Hellstone",
    59: "Mud Block",
    60: "Jungle Grass Block",
    62: "Jungle Vines",
    63: "Gem Sapphire",
    64: "Gem Ruby",
    65: "Gem Emerald",
    66: "Gem Topaz",
    67: "Gem Amethyst",
    68: "Gem Diamond",
    69: "Jungle Thorns",
    70: "Mushroom Grass Block",
    75: "Obsidian Brick",
    76: "Hellstone Brick",
    80: "Cactus",
    107: "Cobalt Ore",
    108: "Mythril Ore",
    109: "Hallowed Grass Block",
    111: "Adamantite Ore",
    112: "Ebonsand Block",
    115: "Hallowed Vines",
    116: "Pearlsand Block",
    117: "Pearlstone Block",
    118: "Pearlstone Brick",
    119: "Iridescent Brick",
    120: "Mudstone Block",
    121: "Cobalt Brick",
    122: "Mythril Brick",
    123: "Silt Block",
    124: "Wooden Beam",
    127: "Ice (Ice Rod)",
    130: "Active Stone Block",
    131: "Inactive Stone Block",
    140: "Demonite Brick",
    145: "Candy Cane Block",
    146: "Green Candy Cane Block",
    147: "Snow Block",
    148: "Snow Brick",
    150: "Adamantite Beam",
    151: "Sandstone Brick",
    152: "Ebonstone Brick",
    153: "Red Stucco",
    154: "Yellow Stucco",
    155: "Green Stucco",
    156: "Gray Stucco",
    157: "Ebonwood",
    158: "Rich Mahogany",
    159: "Pearlwood",
    160: "Rainbow Brick",
    161: "Ice Block",
    162: "Thin Ice",
    163: "Purple Ice Block",
    164: "Pink Ice Block",
    166: "Tin Ore",
    167: "Lead Ore",
    168: "Tungsten Ore",
    169: "Platinum Ore",
    170: "Pine Tree Block",
    175: "Tin Brick",
    176: "Tungsten Brick",
    177: "Platinum Brick",
    179: "Green Moss",
    180: "Yellow Moss",
    181: "Red Moss",
    182: "Blue Moss",
    183: "Purple Moss",
    188: "Cactus",
    189: "Cloud",
    190: "Glowing Mushroom",
    191: "Living Wood Wand",
    192: "Leaf Wand",
    193: "Slime Block",
    194: "Bone Block",
    195: "Flesh Block",
    196: "Rain Cloud",
    197: "Frozen Slime Block",
    198: "Asphalt Block",
    199: "Crimson Grass Block",
    200: "Red Ice Block",
    202: "Sunplate Block",
    203: "Crimstone Block",
    204: "Crimtane Ore",
    205: "Crimson Vine",
    206: "Ice Brick",
    208: "Shadewood",
    211: "Chlorophyte Ore",
    213: "Rope",
    214: "Chain",
    221: "Palladium Ore",
    222: "Orichalcum Ore",
    223: "Titanium Ore",
    224: "Slush Block",
    225: "Hive Wand",
    226: "Lihzahrd Brick",
    229: "Honey Block",
    230: "Crispy Honey Block",
    232: "Wooden Spike",
    234: "Crimsand Block",
    248: "Palladium Column",
    249: "Bubblegum Block",
    250: "Titanstone Block",
    251: "Pumpkin",
    252: "Hay",
    253: "Spooky Wood",
    255: "Amethyst Gemspark Block (Offline)",
    256: "Topaz Gemspark Block (Offline)",
    257: "Sapphire Gemspark Block (Offline)",
    258: "Emerald Gemspark Block (Offline)",
    259: "Ruby Gemspark Block (Offline)",
    260: "Diamond Gemspark Block (Offline)",
    261: "Amber Gemspark Block (Offline)",
    262: "Amethyst Gemspark Block",
    263: "Topaz Gemspark Block",
    264: "Sapphire Gemspark Block",
    265: "Emerald Gemspark Block",
    266: "Ruby Gemspark Block",
    267: "Diamond Gemspark Block",
    268: "Amber Gemspark Block",
    272: "Cog",
    273: "Stone Slab",
    274: "Sandstone Slab",
    284: "Copper Plating",
    311: "Dynasty Wood",
    312: "Red Dynasty Shingles",
    313: "Blue Dynasty Shingles",
    315: "Coralstone Block",
    321: "Boreal Wood",
    322: "Palm Wood",
    325: "Tin Plating",
    326: "Waterfall Block",
    327: "Lavafall Block",
    328: "Confetti Block",
    329: "Midnight Confetti Block",
    330: "Copper Coin Pile",
    331: "Silver Coin Pile",
    332: "Gold Coin Pile",
    333: "Platinum Coin Pile",
    336: "Living Fire Block",
    340: "Living Cursed Fire",
    341: "Living Demon Fire",
    342: "Living Frost Fire",
    343: "Living Ichor",
    344: "Living Ultrabright Fire",
    345: "Honeyfall Block",
    346: "Chlorophyte Brick",
    347: "Crimtane Brick",
    348: "Shroomite Plating",
    350: "Martian Conduit Plating",
    351: "Chimney Smoke",
    352: "Crimtane Thorns",
    353: "Vine Rope",
    357: "Smooth Marble Block",
    365: "Silk Rope",
    366: "Web Rope",
    367: "Marble Block",
    368: "Granite Block",
    369: "Smooth Granite Block",
    370: "Meteorite Brick",
    371: "Pink Slime Block",
    379: "Bubble",
    381: "Lava Moss",
    382: "Vine Flowers",
    383: "Living Mahogany",
    384: "Living Mahogany Leaves",
    385: "Crystal Block",
    396: "Sandstone",
    397: "Hardened Sand",
    398: "Corrupt Hardened Sand",
    399: "Crimson Hardened Sand",
    400: "Corrupt Sandstone",
    401: "Crimson Sandstone",
    402: "Hallow Hardened Sand",
    403: "Hallow Sandstone",
    404: "Desert Fossil",
    407: "Fossil Ore",
    408: "Lunar Ore",
    409: "Lunar Brick",
    415: "Lunar Block Solar",
    416: "Lunar Block Vortex",
    417: "Lunar Block Nebula",
    418: "Lunar Block Stardust",
    421: "Conveyor Belt (CW)",
    422: "Conveyor Belt (CCW)",
    426: "Red Team Block",
    430: "Green Team Block",
    431: "Blue Team Block",
    432: "Yellow Team Block",
    433: "Pink Team Block",
    434: "White Team Block",
    446: "Silly Pink Balloon",
    447: "Silly Purple Balloon",
    448: "Silly Green Balloon",
    449: "Blue Streamer",
    450: "Green Streamer",
    451: "Pink Streamer",
    458: "Sandfall",
    459: "Snowfall",
    460: "Snow Cloud",
    472: "Iron Brick",
    473: "Lead Brick",
    474: "Lesion Block",
    477: "Mowed Grass",
    478: "Crimstone Brick",
    479: "Smooth Sandstone",
    481: "Cracked Blue Brick",
    482: "Cracked Green Brick",
    483: "Cracked Pink Brick",
    492: "Mowed Hallowed Grass",
    495: "Shell Pile",
    496: "Anti-Portal Block",
    498: "Spider Nest Block",
    500: "Solar Brick",
    501: "Vortex Brick",
    502: "Nebula Brick",
    503: "Stardust Brick",
    504: "Mystic Snake Coil",
    507: "Gold Starry Block",
    508: "Blue Starry Block",
    512: "Green Moss (on Gray Bricks)",
    513: "Brown Moss (on Gray Bricks)",
    514: "Red Moss (on Gray Bricks)",
    515: "Blue Moss (on Gray Bricks)",
    516: "Purple Moss (on Gray Bricks)",
    517: "Lava Moss (on Gray Bricks)",
    528: "Mushroom Vine",
    534: "Krypton Moss",
    535: "Krypton Moss (on Gray Bricks)",
    536: "Xenon Moss",
    537: "Xenon Moss (on Gray Bricks)",
    539: "Argon Moss",
    540: "Argon Moss (on Gray Bricks)",
    541: "Echo Block",
    546: "Grate",
    557: "Grate (closed)",
    561: "Marble Column",
    562: "Bamboo",
    563: "Large Bamboo",
    566: "Amber Stone Block",
    574: "Boreal Beam",
    575: "Rich Mahogany Beam",
    576: "Granite Column",
    577: "Sandstone Column",
    578: "Mushroom Beam",
    618: "Stone Accent Slab",
};